import React, { } from 'react';
import {Switch, Route, useHistory} from "react-router-dom";
import {ThemeProvider} from '@material-ui/core/styles';
import {SnackbarProvider} from 'notistack';

import BrickList from "./BrickList";
import GetStarted from './GetStarted';
import Session from './Session';
import {theme} from "./theme";
import {incrementElement, getNewSession} from './api';


function App() {
  const history = useHistory();

  return (
    <SnackbarProvider maxSnack="3">
      <ThemeProvider theme={theme}>
        <div className="App" 
        style={{padding: '.5em'}}
        >
          <Switch>
            <Route 
              path="/session/:sessionId/bricklist/:id" 
              render={(props) => <BrickList {...props} incremented={incrementElement} />}/>
            <Route path="/session/:sessionId" render={(props) => (
              <Session {...props}/>
            )}/>
            <Route path="/">
              <GetStarted getStarted={async ()=>{
                const session = await getNewSession();
                history.push(`/session/${session.sessionId}`)
              }}></GetStarted>
            </Route>
          </Switch>
        </div>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;

