import axios from "axios"

const host = process.env.REACT_APP_API_HOST; // 'http://apmb.local:4000';
const root = process.env.REACT_APP_API_ROOT || '/api';


export const getBrickList = async (id) => {
    const resp = await axios.get(`${host}${root}/bricklist/${id}`);
    return resp.data;
}

export const getSet = async (id) => {
    const resp = await axios.get(`${host}${root}/set/${id}`);
    return resp.data;
}

export const getNewSession = async () => {
    const resp = await axios.post(`${host}${root}/session`);
    return resp.data;
}

export const getSession = async (sessionId) => {
    const resp = await axios.get(`${host}${root}/session/${sessionId}`);
    return resp.data;
}

export const getFoundBricks = async (sessionId, id) => {
    const resp = await axios.get(`${host}${root}/session/${sessionId}/bricklist/${id}`);
    return resp.data;
}

export const newPickList = async (sessionId, id) => {
    const resp = await axios.post(`${host}${root}/session/${sessionId}/bricklist/${id}`);
    if (resp.status === 200){
        return await getSession(sessionId);
    }else{
        return resp.status;
    }

}

export const deletePickList = async (sessionId, id) => {
    const resp = await axios.delete(`${host}${root}/session/${sessionId}/bricklist/${id}`);
    if (resp.status === 200){
        return await getSession(sessionId);
    }else{
        return;
    }
}

export const incrementElement = async ({sessionId, id, elementId, found}) => {
    return axios.post(`${host}${root}/session/${sessionId}/bricklist/${id}/element/${elementId}`, {found});
}

