import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import { Button, CardActions, Grid, ThemeProvider } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';

import DeleteIcon from '@material-ui/icons/Close';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';

import SetInfo from './SetInfo';
import SearchForm from './SearchForm';
import {getSession, deletePickList, getSet} from "./api";
import {yella} from "./theme";

export default function Session({match}){

    const {sessionId} = match.params;
    const history = useHistory();

    const [lists, setLists] = useState([]);
    const [setInfos, setSetInfos] = useState({});

    useEffect(()=>{
        (async ()=>{
            const session = await getSession(sessionId);
            setLists(session.pickLists);
        })();
    }, [sessionId]);

    useEffect(()=>{
        (async ()=>{
            console.log("UE picklists", lists);
            // TODO: this is overkill if we already know about some of these lists. when a user adds a new one, just fetch that.
            const sets = await Promise.all(lists.map(pl => getSet(pl.id)));
            setSetInfos(sets.reduce((out, si)=> {
                console.log(si);
                out[si.setNum] = si;
                return out;
            }, {}));
        })().catch((err)=>{console.error(err)});
    }, [lists]);


    return <div>
        <h1>Your Pick Lists:</h1>
        <h2>Bookmark this page!</h2>
        <h3>This is your page. There are many like it, but this one is yours.</h3>

        <Grid container spacing={2}>
        {lists.length > 0 ? lists.map(({id, completion})=> 
            <Grid key={id} item xs={12} sm={4}>
                <Card elevation={3}>
                    <CardActionArea onClick={()=>history.push(`/session/${sessionId}/bricklist/${id}`)}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <SetInfo
                                    id={id}
                                    setInfo={setInfos[`${id}-1`] ? setInfos[`${id}-1`] : {}}
                                    onDelete={async (ev)=>{
                                        ev.stopPropagation()
                                        const newSession = await deletePickList(sessionId, id);
                                        setLists(newSession.pickLists);
                                    }}
                                />
                                <Grid item xs={12}>
                                    <ThemeProvider theme={yella}>
                                    <LinearProgress variant="determinate" value={completion*100} style={{
                                        height: "2em",
                                        borderRadius: 10,
                                    }}/>
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        ): <Grid item xs={12}>You have no picklists yet. Enter an ID below.</Grid>}
            <Grid item xs={12} sm={4}>
                <Card elevation={3}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h2>New Picklist:</h2>
                            </Grid>
                            <Grid item xs={12}>
                                <SearchForm sessionId={sessionId} numberOfPickLists={lists.length} updateSession={(session)=>{
                                    setLists(session.pickLists);
                                }} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>


    </div>;
}
Session.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            sessionID: PropTypes.string,
        })
    })
}