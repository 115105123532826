import Fab from '@material-ui/core/Fab';
import CardMedia from '@material-ui/core/CardMedia';
import DeleteIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { swapUrlForCache } from "./utilities";


export default function SetInfo({id, setInfo, onDelete}) {
    const {
        name,
        year,
        numParts,
        setImgUrl,
    } = setInfo;
    return <>
        <Grid item xs={8}>
            <h1>{id}</h1>
        </Grid>
        <Grid item xs={4}>
            <Fab size="small"
                color="default"
                style={{
                    opacity: .5,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    margin: 10,
                }}
                onClick={onDelete}
                >
                <DeleteIcon />
            </Fab>
        </Grid>
        <Grid item xs={12} sm={6}>
            <h3>{name} ({year})</h3>
            <h2>{numParts} parts</h2>
        </Grid>
        <Grid item xs={12} sm={6}>
            <CardMedia
                component="img"
                image={swapUrlForCache(setImgUrl)}
                style={{
                    marginRight: 20,
                    marginTop: 20,
                }}
            />
        </Grid>
    </>;
}
