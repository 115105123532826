import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import UndoIcon from '@material-ui/icons/Undo';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar'; 
import Toolbar from '@material-ui/core/Toolbar'; 
import Typography from '@material-ui/core/Typography'; 
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Box, ThemeProvider} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

import {useDrawerStyles} from "./styles";
import {getFoundBricks, getBrickList} from "./api";
import Brick from './Brick2';
import HideOnScroll from './HideOnScroll';
import {yella} from './theme';
import { swapUrlForCache } from './utilities';


const pingInterval = (1000 * 30);

const legoTransform = (data) => data.bricks.map(d => ({
    itemNumber: d.itemNumber,
    itemQuantity: d.itemQuantity,
    description: d.description,
    color: d.color,
    colorFamily: d.colorFamily,
    imageUrl: d.imageUrl,
}));

async function getBrickData (id) {
    const bd = await getBrickList(id);
    return legoTransform(bd)
}


function BrickList({match, incremented}){
    const {sessionId, id} = match.params;

    const [jumpItem, setJumpItem] = useState(null);
    const [brickData, setBrickData] = useState([]);
    const [foundData, setFoundData] = useState({});
    const [saving, setSaving] = useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(()=>{
        (async ()=>{
            const bd = await getBrickData(id)
            setBrickData(bd);
        })();
    }, [id]);


    useEffect(()=>{
        const fetchData = async () => {
            const result = await getFoundBricks(sessionId, id);
            setFoundData(result);
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, id]);

    const fetchAndReconcile = useCallback(
        async (sessionId, id) => {
            const serverBrickData = await getFoundBricks(sessionId, id);
            if (!saving) {
                setFoundData({
                    ...foundData,
                    ...serverBrickData,
                });
            }else{
                console.log("Discarding fetch of server found list because we're saving our own update");
            }
        },
        [foundData, saving],
    );    


    useEffect(() =>{
      const interval = setInterval(async () => fetchAndReconcile(sessionId, id), pingInterval)
      return () => clearInterval(interval)
    }, [sessionId, id, fetchAndReconcile]);


    const undo = (r) => {
      setFoundData({
        ...foundData,
        [r.itemNumber]: (foundData[r.itemNumber] || 0) -1,
      });
      closeSnackbar(r.itemNumber);
    }
    

    const neededFilter = (r)=>(foundData[r.itemNumber] || 0)<r.itemQuantity;
    const hasFilter = (r)=>(foundData[r.itemNumber] || 0)>=r.itemQuantity;
  
    const increment = (r,amount)=>{
      if ((foundData[r.itemNumber] || 0) + amount < 0 || (foundData[r.itemNumber] || 0) + amount > r.itemQuantity) return;

      const newCount = (foundData[r.itemNumber] || 0) + amount;
      if (newCount >= r.itemQuantity) {
        enqueueSnackbar(
          `${r.description} Done!`,
          {
            key: r.itemNumber,
            variant: 'success',
            action: <IconButton onClick={undo}><UndoIcon htmlColor="#fff" /></IconButton>
          });
      }
      setSaving(true);
      setFoundData({
        ...foundData,
        [r.itemNumber]: newCount,
      });
      incremented({sessionId, id, elementId: r.itemNumber, found: newCount});
      setSaving(false);
    }

    const sortByColor = (a) => a.sort((a, b) => {
      const acf = a.colorFamily.toUpperCase();
      const bcf = b.colorFamily.toUpperCase();
      if (acf < bcf) return -1;
      if (acf > bcf) return 1;
      return 0;
    });

    const colors = sortByColor(brickData.filter(neededFilter)).reduce((out, bd) => {
      if (!out[bd.colorFamily]) {
        out[bd.colorFamily] = {
          ...bd,
        };
      }
      return out;
    }, {});

    const classes = useDrawerStyles();
    const [open, setOpen] = React.useState(false);
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };
    
    const totalNeeded = brickData.reduce((out, bditem) => {
      out += bditem.itemQuantity;
      return out;
    },0);
    const totalFound = Object.values(foundData).reduce((t, n) => t + n, 0);

    return <>
      <HideOnScroll>
        <AppBar
          position="fixed"
          className={`${classes.appBar} ${open ? classes.appBarShift : ''}`}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={`${classes.menuButton} ${open ? classes.hide : ''}`}
            >
              <MenuIcon />
            </IconButton>
            <Box width="100%" display="flex" flexDirection="row">
              <Box m={1}>
                <Typography variant="h6" noWrap>{id}</Typography>
              </Box>
              <Box m={1} flexGrow={1}>
                <ThemeProvider theme={yella}>
                  <LinearProgress variant="determinate" value={(totalFound/totalNeeded)*100} style={{
                      height: "2em",
                      borderRadius: 10,
                  }}/>
                </ThemeProvider>            
              </Box>
              <Box m={1}>
                <Typography variant="h6" noWrap>
                  {totalFound} / {totalNeeded}
                </Typography>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {Object.values(colors).map((colorSampleItem) => (
            <ListItem button key={colorSampleItem.colorFamily} onClick={() => {
              setJumpItem(colorSampleItem.itemNumber);
              handleDrawerClose();
              }}>
              <ListItemText primary={colorSampleItem.colorFamily} />
              <ListItemIcon>
                <div style={{
                  width: '2em', 
                  height: '2em',
                  backgroundImage: `url(${swapUrlForCache(colorSampleItem.imageUrl)})`,
                  backgroundPositionX: "40%",
                  backgroundPositionY: "40%",
                }}></div>
                {/* <img alt="" style={{width: '2em', height: '2em'}} src={swapUrlForCache(colorSampleItem.imageUrl)} /> */}
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Drawer>
    
    <Grid container spacing={2} style={{marginTop: "4em"}}>
        {sortByColor(brickData.filter(neededFilter)).map(r => 
          <Brick
            scrolledTo={r.itemNumber === jumpItem}
            key={r.itemNumber}
            brickSpec={r}
            quantityFound={foundData[r.itemNumber] || 0}
            increment={()=>increment(r,1)}
            decrement={()=>increment(r,-1)}
            />
        )}
        {sortByColor(brickData.filter(hasFilter)).map(r => 
          <Brick 
            key={r.itemNumber}
            filled={true}
            brickSpec={r}
            quantityFound={foundData[r.itemNumber] || 0}
            decrement={()=>increment(r,-1)}
            />
        )}
      </Grid>
  </>
}

export default BrickList