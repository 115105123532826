import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
  
export default function GetStarted ({getStarted}) {


    return <Grid container spacing={1}>
        <Grid item>
            <Card elevation={0} style={{
                // borderRadius: 0,
            }}>
                {/* <CardMedia component="img" height={200} image={"/pieces-of-lego-displayed-during-the-exhibition-at-alexandra-news-photo-1586272389.jpg"}/> */}
                <CardContent style={{
                    // position: "relative",
                    // top: -200,
                    // color: "#ffffff"
                }}>
                    <h1>Brick Pick List</h1>
                    <p>An easy to use checklist of bricks you need to re-assemble old kits from your giant pile of LEGO®.</p>

                    <Button variant="contained" onClick={getStarted}>Get Started</Button>
                </CardContent>
            </Card>
                {/* <Card className={classes.root}>
                    <CardActionArea onClick={()=>increment(itemNumber)}>
                        <CardMedia
                            className={classes.media}
                            component="img"
                            alt={description}
                            title={description}
                            height={192}
                            image={imageUrl.replace(/https?:\/\/(www.)?lego.com/, "http://apmb.local:8080")}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {toTitleCase(description)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {colorFamily}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions style={{justifyContent: "space-around"}}>
                        <Fab size="small" onClick={()=>decrement(itemNumber)}><RemoveIcon /></Fab>
                        <Typography >{quantityFound}/{itemQuantity}</Typography>
                        <Fab size="small" onClick={increment ? ()=>increment(itemNumber) : undefined}><AddIcon /></Fab>
                    </CardActions>
                </Card> */}
            </Grid>
        </Grid>
}