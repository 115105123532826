import ReactDOM from 'react-dom';
import React, {useEffect, useRef} from 'react';

import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import { swapUrlForCache } from './utilities';

const useStyles = makeStyles({
    root: {
      maxWidth: 192,
    },
    media: {
      height: 192,
    },
});
  
function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
function Brick({
    scrolledTo = false,
    brickSpec,
    quantityFound,
    increment,
    decrement,
}){
    const classes = useStyles();
    const {
        itemNumber,
        itemQuantity,
        imageUrl,
        colorFamily,
        description,
    } = brickSpec;

    const divRef = useRef(null);

    let node = ReactDOM.findDOMNode(divRef.current);
    useEffect(()=>{
        if (scrolledTo && node) {
            node.scrollIntoView({block: 'start', behavior: 'smooth'});
        }    
    }, [scrolledTo, node]);

    const done = quantityFound >= itemQuantity;

    const doneStyle = {backgroundColor: "#aaa", opacity: .5}

    return <Grid item xs={6} sm={3} md={3} lg={3}>
            <div ref={divRef}>
            <Card className={classes.root} style={done? doneStyle : {}} >
                <CardActionArea onClick={increment ? ()=>increment(itemNumber) : undefined}>
                    <CardMedia
                        className={classes.media}
                        component="img"
                        alt={description}
                        title={description}
                        height={192}
                        image={swapUrlForCache(imageUrl)}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {toTitleCase(description)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {colorFamily}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions style={{justifyContent: "space-around"}}>
                    <Fab size="small" onClick={()=>decrement(itemNumber)}><RemoveIcon /></Fab>
                    <Typography >{quantityFound}/{itemQuantity}</Typography>
                    <Fab size="small" onClick={increment ? ()=>increment(itemNumber) : undefined}><AddIcon /></Fab>
                </CardActions>
            </Card>
            </div>
        </Grid>
}

export default Brick;
