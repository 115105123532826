const cdnHostOverride = process.env.REACT_APP_CDN_HOST;


export const swapUrlForCache = (imageUrl) => {
    if (/https?:\/\/(www\.)?lego\.com/.test(imageUrl)) {
        return imageUrl.replace(/https?:\/\/(www\.)?lego\.com/, cdnHostOverride || document.location.origin)
    }
    if (/https?:\/\/cdn\.rebrickable\.com/.test(imageUrl)) {
        return imageUrl.replace(/https?:\/\/cdn\.rebrickable\.com/, cdnHostOverride || document.location.origin)
    }
    return imageUrl;
}
