import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import {
    newPickList,
} from "./api";

export default function SearchForm({
    sessionId,
    updateSession,
    numberOfPickLists,
}){
    const [legoId, setLegoId] = useState('');

    const [searching, setSearching] = useState(false);

    const [error, setError] = useState('');

    const formSubmit = async (ev)=> {
        setSearching(true);
        setError(null);
        if (ev) ev.preventDefault();

        try {
            const newSessionState = await newPickList(sessionId, legoId);

            updateSession(newSessionState);
            setLegoId('');
        }
        catch (err) {
            if (err.response && err.response.status) {
                if (err.response.status === 404) {
                    setError("Not Found");
                }else{
                    setError("Unknown Error");
                }                
            }
        }
        setSearching(false);
    }


    return (
        <form noValidate autoComplete="off" onSubmit={formSubmit}>
            <Grid container spacing={1}>
                {error && <Grid item xs={12}>
                    <Alert severity="error">{error}</Alert>
                </Grid>}
                <Grid item xs={7}>
                    <TextField
                        style={{width: "100%"}}
                        label="Lego ID"
                        variant="outlined"
                        margin="dense"
                        value={legoId}
                        onChange={({target})=>{setError(null); setLegoId(target.value)}}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        style={{
                            marginTop: 10,
                        }}
                        variant="contained"
                        margin="dense"
                        color={"primary"}
                        onClick={formSubmit}
                    >{searching ? <>Search <CircularProgress size={10} color="secondary" /></> : "Search"}</Button>
                </Grid>
                {numberOfPickLists === 0 ? 
                    <Grid item xs={12}>
                        <img 
                            style={{
                                maxHeight: '100%',
                                maxWidth: '100%',
                            }}
                            alt="where to find the lego id on a lego box" 
                            src="/whereToFindId.png"
                        /> 
                    </Grid> : 
                    <Grid item xs={12}>(printed on the box / instructions)</Grid>}
            </Grid>
        </form>
    );
}